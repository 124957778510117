import React from 'react';

interface ILogoutViewProps {
    submit : () => void;
}

export class LogoutView extends React.Component<ILogoutViewProps, object> {
    public render() {
        const {
            submit,
        } = this.props;
        return (
            <a onClick={ submit }>
                Log Out
            </a>
        );
    }
}
