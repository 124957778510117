import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Cookies from 'js-cookie';
import { UserSessionActions } from './actions';
import { ISignUpOptionsState } from './index';

import { refreshToken } from "shared/global/refreshJWT";

interface IUserLoginAppProps {
    dispatch : Dispatch<ISignUpOptionsState>;
}

interface IUserLoginAppState {
    hasJWT : boolean;
    hasRefreshToken : boolean;
}

export class UserLoginApp extends React.Component<IUserLoginAppProps, IUserLoginAppState> {
    constructor(props : IUserLoginAppProps) {
        super(props);
        this.state = {
            hasJWT: false,
            hasRefreshToken: true,
        };
    }

    public componentWillMount() {
        refreshToken(() => null, true);
    }

    public componentDidMount() {
        // keep checking if cookie has been set by refreshJWT.ts by forcing the app to periodically re-render...
        setInterval(() => {
            this.setState({
                hasJWT: !!Cookies.get('userSessionIdentifier')
            });
        }, 10000); // was 3000
    }

    public render() {
        const { dispatch } = this.props;
        const { hasJWT } = this.state;

        if (hasJWT) {
            dispatch(UserSessionActions.onSuccessfulSignIn());
            return null;
        } else {
            return (
                <div style={ { display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' } }>
                    <h2>Retrieving User Info...</h2>
                </div>
            );
        }
    }
}

const mapStateToProps = (state : any) => ({});

export const ConnectedUserLoginApp = connect<object, object, object>(mapStateToProps)(UserLoginApp);
