import React from 'react';
import * as ReactDOM from 'react-dom';
import * as Redux from 'redux';

import { Auth } from 'aws-amplify';
import { Provider } from 'shared/components/Provider';

import { SignUpFormActionInterfaces } from 'shared/components/SignUpOrSignIn/SignUpForm/actions';
import { ISignUpOptionsState, SignUpOptionsReducers } from 'shared/components/SignUpOrSignIn/SignUpOptions/reducers';
import { InviteReducers } from './inviteReducers';
import { IUserSessionAppState, UserSessionReducers } from './reducers';

import { ConnectedInviteApp } from './InviteApp';
import { ConnectedUserButtonApp } from './UserButtonApp';
import { ConnectedUserLoginApp } from './UserLoginApp';
import { ConnectedUserLogoutApp } from './UserLogoutApp';

import { ActionInterfaces } from './actions';
import { ActionInterfaces as InviteActionInterfaces } from './inviteActions';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';

import {
    cookieManager,
    queryStringManager,
    unauthenticatedUserSessionManager,
    userAccountInfoService,
    userAccountManager,
    userInviteService,
    userPasswordManager,
    userSessionManager,
} from 'shared/lib/manager';

export interface ISignUpOptionsState {
    userSession : IUserSessionAppState;
    signUpOptionsState : ISignUpOptionsState;
}

const signUpOptionsReducers = Redux.combineReducers({
    userSession: UserSessionReducers,
    signUpOptionsState: SignUpOptionsReducers,
});

const inviteReducers = Redux.combineReducers({
    userSession: UserSessionReducers,
    inviteState: InviteReducers,
    signUpOptionsState: SignUpOptionsReducers, // used by ConnectedSignupOptions in InviteApp–
});

// MAIN
const services : ActionInterfaces.IServices & SignUpFormActionInterfaces.IServices & InviteActionInterfaces.IServices = {
    userSessionCreator: userSessionManager,
    userSessionReader: userSessionManager,
    userSessionTerminator: userSessionManager,
    userAccountInfoReader: userAccountInfoService,
    queryStringReader: queryStringManager,
    cookieDeleter: cookieManager,
    cookieReader: cookieManager,
    userPasswordManager,
    userSessionManager,
    userAccountManager,
    unauthenticatedUserSessionManager,
    djangoApiManager: new DjangoApiManager(),
    userInviteService
};
Auth.configure(window.AMPLIFY_CONFIG);

const userLoginAppElement = document.getElementById('user-login-app');
if (userLoginAppElement) {
    ReactDOM.render(
        <Provider appReducers={ signUpOptionsReducers } services={ services }>
            <ConnectedUserLoginApp />
        </Provider>,
        userLoginAppElement
    );
}

const userButtonAppElement = document.getElementById('user-button-app-mount');
if (userButtonAppElement) {
    ReactDOM.render(
        <Provider appReducers={ signUpOptionsReducers } services={ services }>
            <ConnectedUserButtonApp />
        </Provider>,
        userButtonAppElement
    );
}

const userLogoutAppElement = document.getElementById('user-logout-app');
if (userLogoutAppElement) {
    ReactDOM.render(
        <Provider appReducers={ signUpOptionsReducers } services={ services }>
            <ConnectedUserLogoutApp />
        </Provider>,
        userLogoutAppElement
    );
}

const inviteAppElement = document.getElementById('invite-app');
if (inviteAppElement) {
    ReactDOM.render(
        <Provider appReducers={ inviteReducers } services={ services }>
            <ConnectedInviteApp />
        </Provider>,
        inviteAppElement
    );
}
